import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';
import { useState } from 'react';
import { createFormData } from 'services/form';

export interface QueryTransactionCardItem {
    id: number;
    amount: {
        price: number;
        currency: '_PERCENT';
        priceInThousandths: number;
    };
    maxDiscountAmount: null | number;
    minimumToUse: null | number;
    remainAmount: null | number;
    thumbnail: string;
    title: string;
    type: 'COUPON' | 'VOUCHER';
    useCondition: string;
}

export type QueryTransactionCards = QueryTransactionCardItem[];

interface PriceDTO {
    currency: string;
    price: number;
    priceInThousandths: number;
}

interface BuyResults {
    success?: boolean;
    invoiceId: number;
    discountCode: string;
    discountCondition: string;
    totalAmount: PriceDTO;
    cashAmount: PriceDTO;
}

type RequestResults = any;

export default function useQueryTransaction() {
    const [isLoading, setLoader] = useState(false);
    const [buyResults, setBuyResults] = useState<BuyResults>(null);
    const [requestResults, setRequestResults] = useState<RequestResults>(null);

    const getCards = async (token: string) => {
        setLoader(true);

        try {
            const response = await TaooServerClient.get<QueryTransactionCards>(endpoints.salePoint.getCards(token));

            if (!response || !response?.data) {
                throw new Error('API Error!');
            }

            return response?.data;
        } catch (error) {
            handleCatch(error);

            return null;
        } finally {
            setLoader(false);
        }
    };

    const onBuy = async (token: string, cardId: number | null) => {
        setLoader(true);

        try {
            const response = await TaooServerClient.post<BuyResults>(endpoints.salePoint.buy, {
                token,
                cardId,
            });

            if (!response || !response?.data) {
                throw new Error('API Error!');
            }

            const { data } = response;

            setBuyResults(data);

            return data;
        } catch (error) {
            handleCatch(error);

            return null;
        } finally {
            setLoader(false);
        }
    };

    const onRequest = async (invoiceId: number, amount: number, document: File) => {
        setLoader(true);

        try {
            const formData = createFormData({
                invoiceId,
                amount,
                document,
            });
            const response = await TaooServerClient.post<BuyResults>(endpoints.salePoint.request, formData);

            if (!response || !response?.data) {
                throw new Error('API Error!');
            }

            const { data } = response;

            setRequestResults(data);

            return data;
        } catch (error) {
            handleCatch(error);

            return null;
        } finally {
            setLoader(false);
        }
    };

    return {
        isLoading,
        getCards,
        onBuy,
        buyResults,
        onRequest,
        requestResults,
    };
}

const handleCatch = (error) => {
    console.error(error?.message ? error.message : error);
};
