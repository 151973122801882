import classNames from 'classnames';

import './queryCardItem.scss';

interface Props {
    id: number;
    title: string;
    price: string;
    selected: boolean;
    onClick: (id: number) => void;
}

const QueryCardItem = ({
    id, title, price, onClick, selected,
}: Props) => (
    <div className={classNames('query-card-item', { selected })} onClick={() => onClick(id)}>
        <div className={classNames('query-card-item-radio', { selected })} />
        <p>{title}</p>
        <div className="query-card-item-price">
            <p>{price}</p>
        </div>
    </div>
);

export default QueryCardItem;
