import { popupVariant } from 'constants';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Img from 'components/img/Img';
import { domain } from 'constants/api';
import { UnknownValue } from 'constants/defaults';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import replacePathParams from 'services/utils/replacePathParams';

import styles from './index.module.scss';

function Popup({ data }) {
    const lang = useLocale();

    const { logo, sellPrice } = useMemo(() => {
        if (!data) {
            return null;
        }

        return {
            logo:      data?.logo ? `${ domain }/uploads/${ data.logo }` : null,
            sellPrice: `${ data?.amount?.price }${ data?.amount?.currency }` || UnknownValue,
        };
    }, [data]);

    return (
        <motion.div
            variants={popupVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.popup_wrapper}
        >
            <div className={styles.image_wrapper}>{logo ? <Img src={logo} /> : null}</div>
            <div className={styles.transaction_description_item}>
                <div className={styles.item_header}>
                    <FormattedMessage id="popup.transacgtion-price" />
                </div>
                <div className={styles.price}>{sellPrice || UnknownValue}</div>
            </div>
            <div className={styles.transaction_description_item}>
                <div className={styles.item_header}>
                    <FormattedMessage id="popup.seller-name" />
                </div>
                <div className={styles.item_details}>{data?.seller || UnknownValue}</div>
            </div>
            <div className={styles.transaction_description_item}>
                <div className={styles.item_header}>
                    <FormattedMessage id="scan.popup.store" />
                </div>
                <div className={styles.item_details}>{data?.store || UnknownValue}</div>
            </div>
            <div className={styles.transaction_description_item}>
                <div className={styles.item_header}>
                    <FormattedMessage id="popup.transaction-code" />
                </div>
                <div className={styles.item_details}>{data?.uuid || UnknownValue}</div>
            </div>

            <Link
                className={styles.goTo}
                to={replacePathParams(All_PATHS_NAMESPACES[lang]['scan-id'].path, {
                    id: data?.uuid,
                })}
            >
                <FormattedMessage id="popup.select-payment-method" />
            </Link>
        </motion.div>
    );
}

export default Popup;
