export const createFormData = (dataObject) => {
    if (!Object.values(dataObject)?.length) {
        return;
    }

    const formData = new FormData();

    Object.entries(dataObject).map(([key, value]) => value != null && formData.append(key, value));

    return formData;
};
