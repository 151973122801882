import { useState } from 'react';
import { useIntl } from 'react-intl';

import useToast from 'components/widgets/toast/useToast';
import { endpoints } from 'constants/api';
import { TRANSACTION_PENDING_ATTACHMENTS, TRANSACTION_PENDING_SELLER_CONFIRMATION } from 'constants/storageKeys';
import TaooServerClient from 'http/axios';
import { deleteStorageItem, getStorageItem, setStorageItem } from 'services/storage';

export default function useTransaction() {
    const { triggerToast } = useToast();
    const intl = useIntl();

    const [attachedData, setAttachedData] = useState(getPendingAttachments() ?? null);

    /**
     *
     * @param {String} invoiceId
     * @returns
     */
    const attach = async (invoiceId) => {
        try {
            const response = await TaooServerClient.post(endpoints.transactions.attach, {
                invoiceId,
            });

            if (response.status === 200) {
                const result = handleResponse(response.data, intl, triggerToast);

                if (result) {
                    setPendingAttachments(result);
                }

                return result;
            }

            return null;
        } catch (error) {
            handleCatch(error);

            return null;
        }
    };

    /**
     *
     * @param {String} invoiceId
     * @param {Array<Number>} selected
     * @returns {Promise}
     */
    const update = async (invoiceId, selected) => {
        try {
            const response = await TaooServerClient.post(endpoints.transactions.update, {
                invoiceId,
                selected,
            });

            if (response.status === 200) {
                const result = handleResponse(response.data, intl, triggerToast);

                if (result) {
                    setAttachedData(setPendingAttachments(result) ?? null);
                }

                return result;
            }

            return null;
        } catch (error) {
            handleCatch(error);
        }
    };

    /**
     *
     * @param {String} invoiceId
     * @param {Array<Number>} selected
     * @param {Boolean} shouldCleanCacheOnSuccess - defaults to false
     * @returns {Promise}
     */
    const buy = async (invoiceId, selected, shouldCleanCacheOnSuccess = false) => {
        try {
            const isSellerConfirmationPending = getPendingSellerConfirmation();

            // if payment awaiting seller confirmation, verify confirmation
            if (isSellerConfirmationPending === true) {
                return await awaitSellerConfirmation(invoiceId);
            }

            // else delete cached confirmation data
            deletePendingSellerConfirmation();

            const response = await TaooServerClient.post(endpoints.transactions.buy, {
                invoiceId,
                selected,
            });

            if (response.status === 200) {
                const result = handleResponse(response.data, intl, triggerToast);

                if (shouldCleanCacheOnSuccess && result && 'hasError' in result && !result.hasError) {
                    deletePendingAttachments();
                }

                if (result.status === 'WAITING_FOR_SELLER') {
                    return await awaitSellerConfirmation(invoiceId);
                }

                return result;
            }

            return null;
        } catch (error) {
            handleCatch(error);
        }
    };

    const awaitSellerConfirmation = async (invoiceId) => new Promise((resolve, reject) => {
        let timesRun = 0;
        const interval = setInterval(async () => {
            const verificationResult = await verifyPayment(invoiceId);

            if (!verificationResult || verificationResult?.status === 'FAILED') {
                triggerToast(
                    intl.formatMessage({
                        id: 'scan-id.transaction.verify.transaction-verification-failed',
                    }),
                    'error',
                );

                interval && clearInterval(interval);
                reject(new Error('transaction verification failed!'));
            }

            if (verificationResult.status === 'VALID') {
                interval && clearInterval(interval);
                resolve(verificationResult);
            }

            // 60 => 5 minutes = 300 seconds / 5 = 60
            if (timesRun + 1 >= 24 && verificationResult.status === 'WAITING_FOR_SELLER') {
                setPendingSellerConfirmation(true);
                triggerToast(
                    intl.formatMessage({
                        id: 'scan-id.transaction.verify.limit-request-exceeded',
                    }),
                    'error',
                );
                clearInterval(interval);

                reject(new Error('transaction verification request limit exceeded!'));
            }

            timesRun++;
        }, 5000); // 5 seconds x 60 times(timesRun) = 5 minutes
    }).catch((e) => console.error(e));

    /**
     *
     * @param {Number} invoiceId
     * @returns
     */
    const verifyPayment = async (invoiceId) => {
        try {
            const response = await TaooServerClient.post(endpoints.transactions.verify, {
                invoiceId,
            });

            if (response.status === 200) {
                const result = handleResponse(response.data, intl, triggerToast);

                return result;
            }

            return null;
        } catch (error) {
            handleCatch(error);
        }
    };

    return {
        attach,
        update,
        buy,
        verifyPayment,

        setPendingSellerConfirmation,
        getPendingSellerConfirmation,
        deletePendingSellerConfirmation,

        setPendingAttachments,
        getPendingAttachments,
        deletePendingAttachments,

        attachedData,
    };
}

const handleCatch = (error) => {
    console.error(error?.message ? error.message : error);
};

const handleResponse = (data, intl, triggerToast) => {
    if ((data && data?.errorReason) || (data?.hasError && data?.errorReason?.toLowerCase()?.includes('error'))) {
        const errorMessage = intl.formatMessage({ id: data.errorReason });

        triggerToast(errorMessage, 'error');

        throw Error(errorMessage);
    }

    return data;
};

const setPendingSellerConfirmation = (status) => setStorageItem(TRANSACTION_PENDING_SELLER_CONFIRMATION, status);
const getPendingSellerConfirmation = () => getStorageItem(TRANSACTION_PENDING_SELLER_CONFIRMATION);
const deletePendingSellerConfirmation = () => deleteStorageItem(TRANSACTION_PENDING_SELLER_CONFIRMATION);

const setPendingAttachments = (data) => setStorageItem(TRANSACTION_PENDING_ATTACHMENTS, data);
const getPendingAttachments = () => getStorageItem(TRANSACTION_PENDING_ATTACHMENTS);
const deletePendingAttachments = () => deleteStorageItem(TRANSACTION_PENDING_ATTACHMENTS);
